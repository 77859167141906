<template>
  <div>
    <v-dialog
      v-model="resetPasswordDialog"
      persistent
      :overlay="false"
      max-width="500px"
      transition="slide-y-transition"
    >
      <v-card>
        <v-card-title primary-title>
          {{ $t('Reset Password for') }} [{{ username }}]

          <v-spacer />
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon>mdil-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="handleSubmit"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.new_password"
                  :append-icon="show1 ? 'mdil-eye' : 'mdil-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  :label="$t('New password')"
                  outlined
                  dense
                  :rules="passwordRules"
                  autocomplete="off"
                  @click:append="show1 = !show1"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="form.confirm_password"
                  :append-icon="show2 ? 'mdil-eye' : 'mdil-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  :label="$t('Confirm Password')"
                  outlined
                  dense
                  :rules="confirmPasswordRules"
                  autocomplete="off"
                  @click:append="show2 = !show2"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              depressed
              @click="closeDialog()"
            >
              {{ $t('Close') }}
            </v-btn>
            <v-btn
              type="submit"
              depressed
              color="primary"
            >
              {{ $t('Add') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    resetPasswordDialog: {
      type: Boolean,
      default: false
    },

    username: {
      type: String,
      required: true
    },

    id: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      valid: true,
      form: {},
      show1: false,
      show2: false,
      passwordRules: [v => !!v || this.$t('New password is required')],
      confirmPasswordRules: [
        v => !!v || this.$t('Confirm password is required')
      ]
    }
  },

  methods: {
    ...mapActions({
      resetPassword: 'resetPassword',
      setSnack: 'setSnack'
    }),

    handleSubmit() {
      if (this.$refs.form.validate()) {
        if (this.form.new_password !== this.form.confirm_password) {
          this.setSnack({
            message: this.$t('Confirm password not match with new password'),
            type: 'error',
            timeout: 3000
          })
        } else {
          const data = { id: this.id, formData: this.form }
          this.resetPassword(data).then(() => {
            this.setSnack({
              message: this.$t('Password reset successfully'),
              type: 'success',
              timeout: 3000
            })
            this.closeDialog()
          })
        }
      }
    },

    closeDialog() {
      this.form = {}
      this.$refs.form.resetValidation()
      this.$emit('closeDialog')
    }
  }
}
</script>

<style lang="scss" scoped></style>
