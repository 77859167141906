<template>
  <div>
    <v-dialog
      v-model="setPermissionDialog"
      persistent
      :overlay="false"
      max-width="500px"
      transition="slide-y-transition"
    >
      <v-card>
        <v-card-title primary-title>
          {{ $t("Set Permission To") }} [{{ username }}]

          <v-spacer />
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon>mdil-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text v-if="setPermissionDialog">
          <v-treeview
            v-model="user_permissions"
            dense
            selectable
            open-on-click
            selected-color="primary"
            :items="allPermissions"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            depressed
            @click="closeDialog()"
          >
            {{ $t("Close") }}
          </v-btn>
          <v-btn
            :disabled="user_permissions.length < 1"
            depressed
            color="primary"
            @click="handleSubmit"
          >
            {{ $t("Save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    setPermissionDialog: {
      type: Boolean,
      default: false,
    },

    userPermissions: {
      type: Array,
      required: true,
    },

    allPermissions: {
      type: Array,
      required: true,
    },

    username: {
      type: String,
      required: true,
    },

    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      user_permissions: [],
    };
  },

  watch: {
    setPermissionDialog: {
      immediate: false,
      handler() {
        if (this.setPermissionDialog) {
          this.user_permissions = [];
          this.userPermissions.forEach((p) => {
            this.user_permissions.push(p.id);
          });
        }
      },
    },
  },

  methods: {
    ...mapActions({
      setPermission: "setPermission",
      setSnack: "setSnack",
    }),

    handleSubmit() {
      const data = {
        id: this.id,
        formData: { user_permissions: this.user_permissions },
      };
      this.setPermission(data).then(() => {
        this.setSnack({
          message: this.$t("Permission set successfully"),
          type: "success",
          timeout: 3000,
        });
        this.closeDialog();
      });
    },

    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="scss" scoped></style>
