<template>
  <div>
    <v-dialog
      v-model="addDialog"
      persistent
      :overlay="false"
      max-width="600px"
      transition="slide-y-transition"
    >
      <v-card>
        <v-card-title primary-title>
          {{ $t('Add User') }}

          <v-spacer />
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon>mdil-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="handleSubmit"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.username"
                  :label="$t('Username')"
                  outlined
                  dense
                  :rules="usernameRules"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.first_name"
                  :label="$t('Phone number')"
                  outlined
                  dense
                  :rules="phoneNumberRules"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.email"
                  :label="$t('Email')"
                  outlined
                  dense
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.password"
                  :append-icon="show1 ? 'mdil-eye' : 'mdil-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  :label="$t('Password')"
                  outlined
                  dense
                  @click:append="show1 = !show1"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.confirm_password"
                  :append-icon="show2 ? 'mdil-eye' : 'mdil-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  :label="$t('Confirm Password')"
                  outlined
                  dense
                  :rules="confirmPasswordRules"
                  @click:append="show2 = !show2"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              :disabled="loadingSubmit"
              depressed
              @click="closeDialog()"
            >
              {{ $t('Close') }}
            </v-btn>
            <v-btn
              type="submit"
              depressed
              color="primary"
              :loading="loadingSubmit"
            >
              {{ $t('Add') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    addDialog: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loadingSubmit: false,
      valid: true,
      form: {},
      show1: false,
      show2: false,
      usernameRules: [v => !!v || this.$t('Username is required')],
      phoneNumberRules: [v => !!v || this.$t('Phone number is required')],
      passwordRules: [v => !!v || this.$t('Password is required')],
      confirmPasswordRules: [
        v => !!v || this.$t('Confirm password is required')
      ]
    }
  },

  methods: {
    ...mapActions({
      addUser: 'addUser',
      setSnack: 'setSnack'
    }),

    handleSubmit() {
      if (this.$refs.form.validate()) {
        if (this.form.password !== this.form.confirm_password) {
          this.setSnack({
            message: this.$t('Confirm password not match with password'),
            type: 'error',
            timeout: 3000
          })
        } else {
          this.loadingSubmit = true
          this.addUser(this.form)
            .then(() => {
              this.setSnack({
                message: this.$t('Added successfully'),
                type: 'success',
                timeout: 3000
              })
              this.$emit('resetData')
              this.resetForm()
              this.loadingSubmit = false
            })
            .catch(() => {
              this.loadingSubmit = false
            })
        }
      }
    },

    resetForm() {
      this.form = {}
      this.$refs.form.resetValidation()
    },

    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style lang="scss" scoped></style>
