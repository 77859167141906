<template>
  <div>
    <v-dialog
      v-model="viewDialog"
      persistent
      :overlay="false"
      max-width="500px"
      transition="slide-y-transition"
    >
      <v-card>
        <v-card-title primary-title>
          {{ $t('View User Info') }}

          <v-spacer />
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon>mdil-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="viewDialog">
          <v-row>
            <v-col cols="12">
              <div class="view-div">
                <strong class="view-title">{{ $t('Username') }}</strong>
                {{ userInfo.username }}
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <div class="view-div">
                <strong class="view-title">{{ $t('Phone number') }}</strong>
                {{ userInfo.first_name || '-' }}
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <div class="view-div">
                <strong class="view-title">{{ $t('Email') }}</strong>
                {{ userInfo.email || '-' }}
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <div class="view-div">
                <strong class="view-title">{{ $t('Status') }}</strong>
                <v-chip
                  label
                  small
                  :color="userInfo.is_active === true ? 'success' : ''"
                >
                  {{ userInfo.is_active === true ? $t('Active') : $t('Deactive') }}
                </v-chip>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <div class="view-div">
                <strong class="view-title">{{ $t('Join date') }}</strong>
                {{ userInfo.date_joined | moment('DD/MM/YYYY') }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            depressed
            @click="closeDialog()"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    viewDialog: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo'
    })
  },

  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>
